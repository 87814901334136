import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { environment } from "src/environments/environment";
import { NativeService } from "./native.service";


@Injectable({
    providedIn: 'root'
})


export class RefreshTokenService {


    constructor(private configservice : ConfigService, private config : ConfigService, private nativeService : NativeService) {
        
    }



    async refreshToken(){

        let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));

        const request =  {           
                "test": !environment.production,
                "company": '900422533',
                "username": dataAccess.User.UserName,
                "password": dataAccess.User.Password,
                "token": dataAccess.token           
        }

        let result = await this.config
        .Post(`${this.config.getUrlBase()}/Login/Login`, request)
        .catch((err) => {
          this.nativeService
            .OnlyAlert('Notificación', err.message, 'warning')
            .then(() => {
            });
        });
  
        if (result && result.success) {
            await this.nativeService.OnlyAlert('Sesión restaurada', result.message, 'warning');
            sessionStorage.setItem('dataAccess', JSON.stringify(JSON.parse(result.result)));
        } else if (result && !result.success) {
            this.nativeService.OnlyAlert('Notificación', result.message, 'warning');
        }

        console.log("Refrescando el token")
    }

}