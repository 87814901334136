import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from 'src/theme/shared/shared.module';
import { AdminComponent } from 'src/theme/Pages/admin/admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RefreshToken } from './interceptors/refresToken.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent
  ],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
  ],
  exports:[
  ],
  providers: [
    StatusBar,
    LottieSplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppPreferences,
    DatePipe,
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshToken, multi : true }
    //multi sirve para usar varios interceptores a la vez 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
