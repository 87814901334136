import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RefreshTokenService } from '../services/refreshToken.service';
import { error } from 'console';
@Injectable()
export class RefreshToken implements HttpInterceptor {
  private hasRetried = false;

  constructor(private refreshTokenservice: RefreshTokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 500 && !this.hasRetried && req.method === 'POST') {
          this.hasRetried = true;
          // Retornamos el flujo que refresca el token y continúa la solicitud
          return from(this.refreshToken(req, next));
        }
        // Reemitimos el error para otros casos
        return throwError(() => error);
      })
    ) as Observable<HttpEvent<any>>; // Aseguramos el tipo correcto
  }

  private async refreshToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    try {
      // Esperamos a que se complete el refresh token
      await this.refreshTokenservice.refreshToken();

      const dataAccess = JSON.parse(sessionStorage.getItem('dataAccess') || '{}');
      if (!dataAccess || !dataAccess.token) {
        throw new Error('No se encontró el token actualizado.');
      }

      // Clonamos la solicitud original con el nuevo token
      const clonedRequest = req.clone({ body: { ...req.body, token: dataAccess.token } });

      // Continuamos con la solicitud clonada
      return next.handle(clonedRequest).toPromise();
    } catch (error: any) {
      // Emitimos el error para manejarlo posteriormente
      return Promise.reject(error);
    }
  }
}
